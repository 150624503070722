import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { reducer } from './Services/reducer/reducer';
import { initialState } from './Services/reducer/initialState';
import AuthServices from './Services/AuthServices';

export const HttpContext = createContext(false);

export const HttpContextProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  const [isLoader, setLoader] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const currentPath = window.location.pathname;
  if (token == null && currentPath !== "/login" && currentPath !== "/register") {
    window.location.href = "/login";
  }

  const httpClient = React.useMemo(() => {
    const instance = axios.create({
      baseURL: window.location.hostname === "goskluch.pro" ? "https://api.signself.ru/api" : "https://spd-3.ru/api",
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    instance.interceptors.request.use((config) => {
      if (!config.url.includes("address") && !config.hideLoader) {
        // setLoader(true);
      }
      return config;
    });

    instance.interceptors.response.use(
      (response) => {
        setLoader(false);
        if (response.config.method === "post" || response.config.method === "put") {
        }
        return response;
      },
      (error) => {
        setLoader(false);
        if (error.response?.status === 400) {
          console.log(JSON.stringify(error.response.data));
        }
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
    return instance;
  }, [token]);

  useEffect(() => {
    AuthServices(dispatch);
  }, [dispatch]);

  return (
    <React.Fragment>
      {window.location.hostname !== "goskluch.pro" &&
        <Backdrop
          sx={{ color: '#fff', zIndex: 999 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <HttpContext.Provider value={{ httpClient, state, dispatch }}>
        {children}
      </HttpContext.Provider>
    </React.Fragment>
  );
};
