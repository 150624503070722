import styles from "./select.module.css";
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ChevronDown } from '../Icon/Icon';
import { Skeleton } from '@mui/material';

export default function Select({ label, disabled, value, onClick, onChange, placeholder, children, skeleton }) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (optionValue) => {
    setIsOpen(false);
    onChange && onChange(optionValue);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectRef]);

  const selectedOption = children?.find(option => option.props.value === value);

  return (
    <div onClick={handleToggle} className={styles.container}>
      {label && (skeleton ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label className={styles.label}>{label}</label>)}
      {skeleton
        ?
        <Skeleton variant='rounded' width='100%' height='40.5px'/>
        :
        <div className={cn(styles.select, { [styles.disabled]: disabled, [styles.focus]: isOpen })} ref={selectRef}>
          <div className={cn({ [styles.placeholder]: !value })}>
            {selectedOption ? selectedOption.props.children : placeholder}
          </div>
          <div className={cn(styles.options, {[styles.optionsVisible]: isOpen})}>
            {children.map(option => (
              <span
                key={option.props.value}
                className={cn(styles.option, {[styles.selectedOption]: option.props.value === value})}
                onClick={() => handleOptionClick(option.props.value)}
              >
              {option.props.children}
            </span>
            ))}
          </div>
          <ChevronDown className={cn(styles.icon, {[styles.iconDisabled]: disabled, [styles.iconOpen]: isOpen})}/>
        </div>
      }
    </div>
  )
}