import React, { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Skeleton } from '@mui/material';

import Button from '../../../../../Components/Button/Button';
import Menu from '../../../../../Components/Menu/Menu';
import Select from '../../../../../Components/Select/Select';
import Input from '../../../../../Components/Input/Input';
import SmallCard from '../../../../../Components/SmallCard/SmallCard';
import FileInput from '../../../../../Components/FileInput/FileInput';
import { mimeTypes } from '../../../../../constants/constants';
import Check from '../../../../../Components/Check/Check';
import Badge from '../../../../../Components/Badge/Badge';
import Datepicker from '../../Datepicker/Datepicker';
import useFileService from '../../../../../Services/FileService';
import { useResidentialComplexService } from '../../../../../Services/ResidentialComplexService';
import ModalCenter from '../../../../../Components/ModalCenter/ModalCenter';
import { usePostNotificationsService } from '../../../../../Services/PostNotifications/PostNotificationsService';
import Signature from '../Signature/Signature';
import AddressInput from '../../../../../Components/Input/AddressInput';
import getStatus from '../../../../../Services/PostNotifications/PostNotificationStatuses';
import TooltipText from '../../../../../Components/Tooltip/TooltipText';
import TooltipLink from '../../../../../Components/Tooltip/TooltipLink';
import FlSubjectInput from '../../../../../Components/Input/FlSubjectInput';
import TooltipIcon from '../../../../../Components/Tooltip/TooltipIcon';
import { HttpContext } from '../../../../../HttpContext';

import styles from '../homes.modal.module.css';
import color from '../../../../../styles/color.module.css';
import save from '../../../../../icons/FloppyDisk.svg';
import pdf from '../../../../../icons/PdfIcon.svg';
import folder from '../../../../../icons/folderIcon.svg';
import info from '../../../../../icons/infoIcon.svg';

const Participant = ({apartment, setApartment, ownerId, setOwnerId, setOpenParticipant, owner, setOwner, setIsSharer}) => {
  const [isSignatureNotice, setIsSignatureNotice] = useState(false);
  const [subjectType, setSubjectType] = useState('fl');
  const [sendTime, setSendTime] = useState(null);
  const [ulSubjects, setUlSubjects] = useState([]);
  const [flSubjects, setFlSubjects] = useState([]);
  const [flSubject, setFlSubject] = useState([]);
  const [ulSubject, setUlSubject] = useState([]);
  const [isEditSubjectType, setIsEditSubjectType] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [statusInfo, setStatusInfo] = useState(null);
  const [notificationError, setNotificationError] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [sendingType, setSendingType] = useState(2);
  const intervalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [historyStatuses, setHistoryStatuses] = useState(null);
  const [isMount, setIsMount] = useState(true);

  const { state } = useContext(HttpContext);

  useEffect(() => {
    if (state?.flSubjects) {
      setFlSubjects(state?.flSubjects)
      setUlSubjects(styles?.ulSubjects)
    }
  }, [state?.flSubjects])

  const {downloadStorageFile, uploadFile, downloadLocalFile} = useFileService();
  const {deleteOwner, getResidentialApartment, updateOwner, createOwner, getNotifications} = useResidentialComplexService();
  const {uploadNotification, sendUlOwner, sendFlOwner, getLetterStatus, getNoticeFile, updateNotification, checkNotification, getLetterHistory} = usePostNotificationsService();

  const sendType = (type) => {
    setSendingType(type)
    setOwner({...owner, SendingType: type})
  }

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const addDays = (dateString, days) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    date.setDate(date.getDate() + days);
    return formatDate(date);
  };

  const toggleStatus = (newStatus) => {
    setOwner(prevOwner => ({
      ...prevOwner,
      MakeAnAppointment: {
        ...prevOwner?.MakeAnAppointment,
        Status: prevOwner?.MakeAnAppointment?.Status !== newStatus ? newStatus : prevOwner?.MakeAnAppointment?.Status,
        DateSigningAct: newStatus === 0 ? new Date() : prevOwner?.MakeAnAppointment?.DateSigningAct
      }
    }))
  };

  const handleInput = (value) => {
    const validValue = value.replace(/[a-zA-Z0-9]/g, '');

    setOwner({
      ...owner,
      MakeAnAppointment: {
        ...owner?.MakeAnAppointment,
        ManagerName: value !== validValue ? validValue : value
      }
    })
  };

  const checkNotificationWithRetry = async (mailId, maxRetries = 30, delay = 2000, attempt = 1) => {
    setNotificationLoading(true);
    try {
      const file = await getNoticeFile(mailId);
      if (file) {
        downloadLocalFile(file, 'Уведомление о вручении.zip').then(() => {
          getResidentialApartment(apartment?.Id).then(selectedApartment => {
            setIsEditSubjectType(false);
            const selectedOwner = selectedApartment?.Owners.find((x) => x.Id === ownerId);
            setOwner(selectedOwner);
            setNotificationLoading(false);
          })
        })
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, delay));
          return checkNotificationWithRetry(mailId, maxRetries, delay, attempt + 1);
        } else {
          setNotificationError(true);
          setNotificationLoading(false);
        }
      } else {
        setNotificationError(true);
        setNotificationLoading(false);
      }
    }
    setNotificationLoading(false);
  };

  const downloadFile = async (mailId) => {
    try {
      const updateResult = await updateNotification(mailId);
      await checkNotificationWithRetry(updateResult);
    } catch (error) {
      setNotificationError(true);
    }
  };

  const handleClick = () => {
    const mailId = requestStatus?.MailId;
    if (mailId) {
      downloadFile(mailId)
    }
  };

  const deleteNotification = () => {
    updateOwner({
      ...owner,
      NotificationFileName: null,
      NotificationFileSigName: null,
      NotificationFileId: null,
      NotificationFileSigId: null,
      ApartmentId: apartment?.Id,
      SendingType: sendingType,
    }).then(() => {
      setOwner({
        ...owner,
        NotificationFileName: null,
        NotificationFileSigName: null,
        NotificationFileId: null,
        NotificationFileSigId: null,
      });
    })
    setIsSignatureNotice(false)
  }

  const deleteNotificationSig = () => {
    updateOwner({
      ...owner,
      NotificationFileSigName: null,
      NotificationFileSigId: null,
      ApartmentId: apartment?.Id,
      SendingType: sendingType,
    }).then(() => {
      setOwner({
        ...owner,
        NotificationFileSigName: null,
        NotificationFileSigId: null,
      });
    })
    setIsSignatureNotice(false)
  }

  const sendNotification = () => {
    updateOwner({ ...owner, ApartmentId: apartment?.Id }).then(() => {
      if (subjectType === 'fl') {
        sendFlOwner({
          FlOwnerId: owner?.FlOwnerId,
          ApartmentId: apartment?.Id
        }).then((code) => {
          setOwner({
            ...owner,
            IsSent: true,
            RequestCode: code
          })
        })
      } else {
        sendUlOwner({
          UlOwnerId: owner?.UlOwnerId,
          ApartmentId: apartment?.Id
        }).then((code) => {
          setOwner({
            ...owner,
            IsSent: true,
            RequestCode: code
          })
        })
      }
    }, (e) => console.log('ERROR',e))
  }

  const fetchStatus = (requestCode) => {
    const pollStatus = () => {
      setHistoryStatuses(null);
      getLetterStatus(requestCode).then((request) => {
          const info = getStatus(request?.Status);
          setRequestStatus(request);
          setStatusInfo(info);

          if (request?.NoticeId !== null) {
            getLetterHistory(request?.NoticeId).then((history) => {
                setHistoryStatuses(history);
              }).catch(() => {
                if (isMount) {
                  setTimeout(pollStatus, 5000);
                }
            });
          } else {
            setTimeout(pollStatus, 5000);
          }
        }).catch(() => {
          setTimeout(pollStatus, 5000);
        });
    };
    pollStatus();
  };

  useEffect(() => {
    const startPolling = () => {
      if (requestStatus?.Status === 'ACCEPTED' || requestStatus?.Status === 'IN_PROGRESS') {
        intervalRef.current = setInterval(() => fetchStatus(owner?.RequestCode), 5000);
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    };

    startPolling();

    return () => {
      setIsMount(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [requestStatus, owner?.RequestCode]);

  const uploadNotify = (file, id) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('ApartmentId', apartment?.Id);
    if (subjectType === 'fl') {
      formData.append('FlOwnerId', owner?.FlOwnerId);
    } else {
      formData.append('UlOwnerId', owner?.UlOwnerId);
    }

    updateOwner({
      ...owner,
      ApartmentId: apartment?.Id,
      Id: id ? id : owner?.Id,
      SendingType: sendingType
    }).then(() => {
      uploadNotification(formData).then(() => {
        getResidentialApartment(apartment?.Id).then(selectedApartment => {
          const selectedId = id ? id : ownerId;
          setApartment(selectedApartment);
          setOwner({
            ...selectedApartment?.Owners.find((x) => x.Id === selectedId),
            SendingType: owner?.SendingType,
            PostalAddress: owner?.PostalAddress,
          })
        })
      })
    })
  }

  useEffect(() => {
    if (owner?.NotificationFileName && owner?.NotificationFileSigName) {
      setIsSignatureNotice(true)
    }
  }, [owner?.NotificationFileName])

  useEffect(() => {
    setIsLoading(true)
    if (ownerId) {
      getResidentialApartment(apartment?.Id).then(selectedApartment => {
        if (selectedApartment?.Owners?.length === 0) {
          setSubjectType('fl');
          setIsEditSubjectType(true);
        } else {
          const selectedOwner = selectedApartment?.Owners.find((x) => x.Id === ownerId);
          setOwner(selectedOwner);
          setSubjectType(selectedOwner?.FlOwnerId ? 'fl' : 'ul');
          setIsEditSubjectType(false);
        }
      }).finally(() => setIsLoading(false))
    } else {
      setIsEditSubjectType(true);
      setIsLoading(false);
    }
  }, [ownerId])

  useEffect(() => {
    const selectedFlSubject = flSubjects?.filter((x) => x.Id === owner?.FlOwnerId);
    const selectedUlSubject = ulSubjects?.filter((x) => x.Id === owner?.UlOwnerId);
    setFlSubject(selectedFlSubject);
    setUlSubject(selectedUlSubject);
    if (owner?.NotificationFileSigName) {
      setIsSignatureNotice(true)
    }
    if (owner?.SendingType) {
      setSendingType(owner?.SendingType)
    }
    if (owner?.RequestCode) {
      fetchStatus(owner?.RequestCode)
    } else {
      setRequestStatus(null)
      setStatusInfo(null);
    }
  }, [owner])

  const changeSignature = (formData) => {
    uploadFile(formData).then(id => {
      updateOwner({
        ...owner,
        NotificationFileSigId: id,
        NotificationFileSigName: `${owner?.NotificationFileName}.sig`,
        ApartmentId: apartment?.Id,
        SendingType: sendingType
      }).then(() => {
        getResidentialApartment(apartment?.Id).then(selectedApartment => {
          setApartment(selectedApartment);
          setOwner(selectedApartment?.Owners.find((x) => x.Id === ownerId))
        })
      }).then(() => setOpenSignatureModal(false))
    });
  }

  return (
    <div>
      {isEditSubjectType && !isLoading &&
        <div className={styles.subjectType}>
          <Button
            isDisabled={subjectType === "fl"}
            onClick={() => {
              setSubjectType("fl")
            }}
            text="Физ Лицо"
          />
          <Button
            isDisabled={subjectType === "ul"}
            onClick={() => {
              setSubjectType("ul")
            }}
            text="Юр Лицо"
          />
        </div>
      }
      <div className={styles.label_wrapper}>
        {isLoading ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label>Участник долевого строительства</label>}
        <Menu>
          <Button onClick={() => setIsEditSubjectType(true)} large type="default" text="Изменить" />
          <Button onClick={() => setIsOpenDeleteModal(true)} large type="error" text="Удалить" />
        </Menu>
      </div>
      {subjectType === 'fl' &&
        <div className={styles.flsubject}>
          <FlSubjectInput
            setValue={(value) => {
              const selectedFlSubject = flSubjects?.filter((x) => x.Id === value.Id)[0];
              setOwner({...owner, ApartmentId: apartment?.Id, FlOwnerId: value.Id, UlOwnerId: null, PostalAddress: selectedFlSubject?.Address?.Note })
            }}
            value={owner?.FlOwnerId}
            disabled={owner?.FlOwnerId && !isEditSubjectType}
            skeleton={isLoading}
          />
          {owner?.FlOwnerId &&
            <div className={styles.button_wrapper}>
              <Button
                type='info'
                onClick={() => {
                  window.open(`/customer/edit/${owner?.FlOwnerId}/fl`, '_blank');
                }}
                large
                text='Перейти в Клиенты'
              />
            </div>
          }
        </div>
      }
      {subjectType === 'ul' &&
        <div className={styles.flsubject}>
          <Select
            placeholder="Выберите участника долевого строительства"
            onChange={(value) => {
              const selectedUlSubject = ulSubjects?.filter((x) => x.Id === value)[0];
              setOwner({...owner, ApartmentId: apartment?.Id, UlOwnerId: value, FlOwnerId: null, PostalAddress: selectedUlSubject?.Address?.Note})
            }}
            value={owner?.UlOwnerId}
            disabled={owner?.UlOwnerId && !isEditSubjectType}
            skeleton={isLoading}
          >
            {ulSubjects.map((subject) => {
              return (
                <option
                  value={subject.Id}
                >{subject.Name}</option>
              );
            })}
          </Select>
        </div>
      }
      <div className={styles.flsubject}>
        {subjectType === 'fl'
          ?
          <Input
            value={flSubject[0]?.Snils}
            label="СНИЛС"
            placeholder="351-456-335 22"
            mask='000-000-000 00'
            onChange={(value) =>
              setFlSubject([{
                ...flSubject[0],
                Snils: value
              }])
            }
            disabled
            skeleton={isLoading}
          />
          :
          <Input
            value={ulSubject[0]?.Inn}
            label="ИНН"
            placeholder="1213312232"
            mask='0000000000'
            onChange={(value) =>
              setUlSubject([{
                ...ulSubject[0],
                Inn: value
              }])
            }
            disabled
            skeleton={isLoading}
          />
        }
      </div>
      <div className={styles.notification}>
        <div className={styles.checkboxes}>
          {isLoading
            ?
            <div className={styles.title_wrapper}>
              <Skeleton variant='rounded' width='350px' height='19.5px'/>
            </div>
            :
            <div className={styles.text_wrapper}>
              <p className={styles.text}>
                Отправка уведомления об окончании строительства
              </p>
              {owner?.IsSent &&
                <Menu>
                  <Button onClick={() => {
                    setOwner({
                      ...owner,
                      NotificationFileId: null,
                      NotificationFileName: null,
                      NotificationFileSigId: null,
                      NotificationFileSigName: null,
                      RequestCode: null,
                      SentDate: null,
                      SendingType: 2,
                      State: null,
                      IsSent: false,
                    })
                    setIsSignatureNotice(false);
                  }} large type="default" text="Отправить повторно" />
                </Menu>
              }
            </div>
          }

          {isLoading ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label>Выберите способ отправки уведомления:</label>}
          <Check
            selected={sendingType === 1}
            label="Электронная почта (e-mail)"
            disabled={owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')}
            onClick={() => {
              if (owner?.SendingType === 1) {
                sendType(null)
              } else {
                sendType(1)
              }
            }}
            skeleton={isLoading}
            bold
          />
          {sendingType === 1 && (
            <div className={styles.wrapper_email}>
              <div>
                <div className={styles.documents}>
                  {isLoading
                    ?
                    <Skeleton variant='rounded' width='100px' height='17px'/>
                    :
                    <label className={styles.label}>
                      Загрузите уведомление об окончании строительства
                    </label>
                  }
                  {owner?.NotificationFileName ? (
                    <SmallCard
                      avatar={pdf}
                      title={owner?.NotificationFileName}
                      onRemove={deleteNotification}
                      onClick={() => downloadStorageFile(owner?.NotificationFileId, owner?.NotificationFileName)}
                      skeleton={isLoading}
                    />
                  ) : (
                    <FileInput
                      accept={[mimeTypes.Pdf]}
                      onChange={(file) => {
                        const formData = new FormData();
                        formData.append('File', file);

                        uploadFile(formData).then(id => {
                          setOwner({
                            ...owner,
                            NotificationFileId: id,
                            NotificationFileName: file.name,
                          });
                        })
                      }}
                    />
                  )}
                </div>
                {owner?.NotificationFileName && !owner?.NotificationFileSigName &&
                  <div className={styles.sigButtons}>
                    <Button isDisabled={isSignatureNotice} onClick={() => setIsSignatureNotice(!isSignatureNotice)} text='Загрузить подпись'/>
                    <span>или</span>
                    <Button onClick={() => {
                      setIsSignatureNotice(false);
                      setOpenSignatureModal(true)
                    }} text='Подписать'/>
                  </div>
                }
                {owner?.NotificationFileName && isSignatureNotice && (
                  <div className={styles.documents}>
                    {owner?.NotificationFileSigName ? (
                      <SmallCard
                        avatar={pdf}
                        title={owner?.NotificationFileSigName}
                        onRemove={deleteNotificationSig}
                        onClick={() => downloadStorageFile(owner?.NotificationFileSigId, owner?.NotificationFileSigName)}
                        skeleton={isLoading}
                      />
                    ) : (
                      <FileInput
                        accept={[mimeTypes.Sig]}
                        label='Загрузите подпись (sig-файл) к уведомлению об окончании строительства'
                        onChange={(file) => {
                          const formData = new FormData();
                          formData.append('File', file);

                          uploadFile(formData).then(id => {
                            setOwner({
                              ...owner,
                              NotificationFileSigId: id,
                              NotificationFileSigName: file.name,
                            });
                          })
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={styles.label_wrapper}>
                {isLoading
                  ?
                  <Skeleton variant='rounded' width='100px' height='17px'/>
                  :
                  <label>
                    Введите e-mail участника долевого строительства для
                    отправки уведомления об окончании строительства
                  </label>
                }
                <Menu>
                  <Button large type="default" text="Изменить" />
                  <Button large type="error" text="Удалить" />
                </Menu>
              </div>
              {subjectType === 'fl'
                ?
                <div className={styles.email}>
                  <Input
                    onChange={(value) => setFlSubject([{
                      ...flSubject[0],
                      Email: value
                    }])}
                    value={flSubject[0]?.Email}
                    placeholder="Введите E-mail участника"
                    skeleton={isLoading}
                  />
                  <Button
                    isDisabled={sendTime || !flSubject[0]?.Email}
                    onClick={() => setSendTime(new Date())}
                    text="Отправить"
                    skeleton={isLoading}
                  />
                </div>
                :
                <div className={styles.email}>
                  <Input
                    onChange={(value) => setUlSubject([{
                      ...ulSubject[0],
                      Email: value
                    }])}
                    value={ulSubject[0]?.Email}
                    placeholder="Введите E-mail участника"
                    skeleton={isLoading}
                  />
                  <Button
                    isDisabled={sendTime || !ulSubject[0]?.Email}
                    onClick={() => setSendTime(new Date())}
                    text="Отправить"
                    skeleton={isLoading}
                  />
                </div>
              }

              {sendTime && (
                <div className={styles.send}>
                  <label className={styles.label_wrapper}>
                    Дата и время отправки уведомления:
                    <b>{sendTime.toLocaleString()}</b>
                  </label>
                  <label className={styles.label_wrapper}>
                    Статус прочтения:
                    <Badge
                      type="success"
                      text="Прочитано: 17:06 12.02.2024"
                    />
                  </label>
                  <label className={styles.label_wrapper}>
                    Проход по ссылке на запись на приемку:
                    <Badge type="error" text="Не осуществлялся" />
                  </label>
                </div>
              )}
            </div>
          )}
          <Check
            selected={sendingType === 2}
            label="Электронное заказное письмо (Почта РФ)"
            disabled={(owner?.NotificationFileName && sendingType === 3) || (owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN'))}
            onClick={() => {
              if (owner?.SendingType === 2) {
                sendType(null)
              } else {
                sendType(2)
              }
            }}
            skeleton={isLoading}
            bold
          />
          {sendingType === 2 && (
            <div className={styles.wrapper_email}>
              <div className={styles.email}>
                <AddressInput
                  value={owner?.PostalAddress}
                  setValue={(value) => {
                    setOwner({
                      ...owner,
                      PostalAddress: value.Note
                    })
                  }}
                  label='Адрес для отправки'
                  disabled={owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')}
                  skeleton={isLoading}
                />
              </div>
              {owner?.PostalAddress &&
                <div>
                  <div className={styles.documents}>
                    {isLoading
                      ?
                      <Skeleton variant='rounded' width='100px' height='17px'/>
                      :
                      <label className={styles.label}>
                        Загрузите уведомление об окончании строительства
                      </label>
                    }
                    {owner?.NotificationFileName ? (
                      <SmallCard
                        avatar={pdf}
                        badge={<Badge type='success' text='Опись сформирована'/>}
                        onRemove={!(owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')) && deleteNotification}
                        onClick={() => downloadStorageFile(owner?.NotificationFileId, owner?.NotificationFileName)}
                        skeleton={isLoading}
                      >
                        <TooltipText text={owner?.NotificationFileName} maxLength={30}/>
                      </SmallCard>
                    ) : (
                      <FileInput
                        accept={[mimeTypes.Pdf]}
                        onChange={(file) => {
                          if (owner?.Id) {
                            uploadNotify(file);
                          } else {
                            createOwner(owner).then((id) => {
                              setOwnerId(id)
                              uploadNotify(file, id)
                            })
                          }
                        }}
                      />
                    )}
                  </div>
                  {owner?.NotificationFileName && !owner?.NotificationFileSigName &&
                    <div className={styles.sigButtons}>
                      <Button
                        onClick={() => {
                          setIsSignatureNotice(false);
                          setOpenSignatureModal(true)
                        }}
                        skeleton={isLoading}
                        text='Подписать'
                      />
                    </div>
                  }
                  {owner?.NotificationFileName && isSignatureNotice && (
                    <div className={styles.documents}>
                      {owner?.NotificationFileSigName && (
                        <SmallCard
                          avatar={pdf}
                          onRemove={!(owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')) && deleteNotificationSig}
                          onClick={() => downloadStorageFile(owner?.NotificationFileSigId, owner?.NotificationFileSigName)}
                          skeleton={isLoading}
                        >
                          <TooltipText text={owner?.NotificationFileSigName} maxLength={50}/>
                        </SmallCard>
                      )}
                    </div>
                  )}
                  {owner?.NotificationFileName && isSignatureNotice &&
                    <Button
                      icon={save}
                      onClick={() => getNotifications(owner?.Id).then((file) => {
                        downloadLocalFile(file, 'Уведомление об окончании строительства.zip')
                      })}
                      text='Скачать'
                      skeleton={isLoading}
                    />
                  }
                </div>
              }
              <Button
                large
                isDisabled={!(
                  requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN' ||
                  (owner?.PostalAddress && owner?.NotificationFileName && owner?.NotificationFileSigName && !owner?.IsSent)
                )}
                text="Отправить"
                onClick={sendNotification}
                skeleton={isLoading}
              />
              {requestStatus?.Status &&
                <div className={styles.sharer}>
                  <label className={styles.label_wrapper}>
                    Статус отправки уведомления:
                    <Badge type={ statusInfo?.badgeType } text={statusInfo?.text}/>
                  </label>
                  {historyStatuses?.length ?
                    <div className={styles.history}>
                      <label>История статусов отправки:</label>
                      {historyStatuses?.map((item) =>
                        <div className={styles.statuses}>
                          <label>{item?.StatusDate}</label>
                          <label>{item?.Status}</label>
                        </div>
                      )}
                    </div>
                    :
                    null
                  }
                  {requestStatus?.Status === 'FAILED' &&
                    <div className={styles.failed}>
                      <span>{requestStatus?.Description}</span>
                    </div>
                  }
                  {requestStatus?.NoticeId &&
                    <div className={styles.sharer}>
                      <SmallCard
                        avatar={folder}
                        title='Уведомление о вручении.zip'
                        onClick={handleClick}
                        badge={notificationError && <Badge type='error' text="Произошла ошибка скачивания" />}
                        isLoad={notificationLoading}
                        loadText='Получение уведомления - 1 минута'
                      />
                      {requestStatus?.MailId &&
                        <div className={styles.sharer}>
                          <div className={styles.sharer}>
                            <label className={styles.tooltip}>
                              Проверить подлинность уведомления о вручении на портале
                              <TooltipLink
                                text='Госуслуг'
                                link={'https://www.gosuslugi.ru/pgu/eds'}
                                tooltipText={
                                  <div className={styles.tooltip_wrapper}>
                                    <h4 className={styles.tooltip_title}>Инструкция:</h4>
                                    <p className={styles.tooltip_text}>1. Выбрать ЭП — в формате PKCS#7</p>
                                    <p className={styles.tooltip_text}>2. Загрузить файл документа</p>
                                    <p className={styles.tooltip_text}>3. Ввести код на изображении</p>
                                    <p className={styles.tooltip_text}>4. Нажать кнопку «Проверить»</p>
                                  </div>
                               }
                              />
                            </label>
                          </div>
                          <div className={styles.sharer}>
                            <label className={styles.tooltip}>
                              Проверить статус получения письма по трек-номеру:
                              <a target='_blank' rel="noopener noreferrer" href={`https://www.pochta.ru/tracking?barcode=${requestStatus?.MailId}`}>
                                {requestStatus?.MailId}
                              </a>
                            </label>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          )}
          <Check
            selected={sendingType === 3}
            label="Бумажное заказное письмо (Почта РФ)"
            disabled={(owner?.NotificationFileName && sendingType === 2) || (owner?.IsSent  && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN'))}
            onClick={() => {
              if (owner?.SendingType === 3) {
                sendType(null)
              } else {
                sendType(3)
              }
            }}
            skeleton={isLoading}
            bold
          />
        </div>
        {sendingType === 3 && (
          <div className={styles.wrapper_email}>
            <div className={styles.email}>
              <AddressInput
                value={owner?.PostalAddress}
                setValue={(value) => {
                  setOwner({
                    ...owner,
                    PostalAddress: value.Note
                  })
                }}
                label='Адрес для отправки'
                disabled={owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')}
                skeleton={isLoading}
              />
            </div>
            {owner?.PostalAddress &&
              <div>
                <div className={styles.documents}>
                  {isLoading
                    ?
                    <Skeleton variant='rounded' width='100px' height='17px'/>
                    :
                    <label className={styles.label}>
                      Загрузите уведомление об окончании строительства
                    </label>
                  }
                  {owner?.NotificationFileName ? (
                    <SmallCard
                      avatar={pdf}
                      badge={<Badge type='success' text='Опись сгенерирована'/>}
                      onRemove={!(owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')) && deleteNotification}
                      onClick={() => downloadStorageFile(owner?.NotificationFileId, owner?.NotificationFileName)}
                      skeleton={isLoading}
                    >
                      <TooltipText text={owner?.NotificationFileName} maxLength={30}/>
                    </SmallCard>
                  ) : (
                    <FileInput
                      accept={[mimeTypes.Pdf]}
                      onChange={(file) => {
                        if (owner?.Id) {
                          uploadNotify(file);
                        } else {
                          createOwner(owner).then((id) => {
                            setOwnerId(id)
                            uploadNotify(file, id)
                          })
                        }
                      }}
                    />
                  )}
                </div>
                {owner?.NotificationFileName && !owner?.NotificationFileSigName &&
                  <div className={styles.sigButtons}>
                    <Button onClick={() => {
                      setIsSignatureNotice(false);
                      setOpenSignatureModal(true)
                    }} text='Подписать'/>
                  </div>
                }
                {owner?.NotificationFileName && isSignatureNotice && (
                  <div className={styles.documents}>
                    {owner?.NotificationFileSigName && (
                      <SmallCard
                        avatar={pdf}
                        onRemove={!(owner?.IsSent && !(requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN')) && deleteNotificationSig}
                        onClick={() => downloadStorageFile(owner?.NotificationFileSigId, owner?.NotificationFileSigName)}
                        skeleton={isLoading}
                      >
                        <TooltipText text={owner?.NotificationFileSigName} maxLength={50}/>
                      </SmallCard>
                    )}
                  </div>
                )}
                {owner?.NotificationFileName && isSignatureNotice &&
                  <Button
                    icon={save}
                    onClick={() => getNotifications(owner?.Id).then((file) => {
                      downloadLocalFile(file, 'Уведомление об окончании строительства.zip')
                    })}
                    text='Скачать'
                  />
                }
              </div>
            }
            <Button
              large
              isDisabled={!(
                requestStatus?.Status === 'FAILED' || requestStatus?.Status === 'UNKNOWN' ||
                (owner?.PostalAddress && owner?.NotificationFileName && owner?.NotificationFileSigName && !owner?.IsSent)
              )}
              text="Отправить"
              onClick={sendNotification}
            />
            {requestStatus?.Status && !isLoading &&
              <div className={styles.sharer}>
                <label className={styles.label_wrapper}>
                  Статус отправки уведомления:
                  <Badge type={ statusInfo?.badgeType } text={statusInfo?.text}/>
                </label>
                {historyStatuses?.length ?
                  <div className={styles.history}>
                    <label>История статусов отправки:</label>
                    {historyStatuses?.map((item) =>
                      <div className={styles.statuses}>
                        <label>{item?.StatusDate}</label>
                        <label>{item?.Status}</label>
                      </div>
                    )}
                  </div>
                  :
                  null
                }
                {requestStatus?.Status === 'FAILED' &&
                  <div className={styles.failed}>
                    <span>{requestStatus?.Description}</span>
                  </div>
                }
                {requestStatus?.NoticeId &&
                  <div className={styles.sharer}>
                    <SmallCard
                      avatar={folder}
                      title='Уведомление о вручении.zip'
                      onClick={handleClick}
                      badge={notificationError && <Badge type='error' text="Произошла ошибка скачивания" />}
                      isLoad={notificationLoading}
                      loadText='Получение уведомления - 1 минута'
                    />
                    {requestStatus?.MailId &&
                      <div className={styles.sharer}>
                        <div className={styles.sharer}>
                          <label className={styles.tooltip}>
                            Проверить подлинность уведомления о вручении на портале
                            <TooltipLink
                              text='Госуслуг'
                              link={'https://www.gosuslugi.ru/pgu/eds'}
                              tooltipText={
                                <div className={styles.tooltip_wrapper}>
                                  <h4 className={styles.tooltip_title}>Инструкция:</h4>
                                  <p className={styles.tooltip_text}>1. Выбрать ЭП — в формате PKCS#7</p>
                                  <p className={styles.tooltip_text}>2. Загрузить файл документа</p>
                                  <p className={styles.tooltip_text}>3. Ввести код на изображении</p>
                                  <p className={styles.tooltip_text}>4. Нажать кнопку «Проверить»</p>
                                </div>
                              }
                            />
                          </label>
                        </div>
                        <div className={styles.sharer}>
                          <label className={styles.tooltip}>
                            Проверить статус получения письма по трек-номеру:
                            <a target='_blank' rel="noopener noreferrer" href={`https://www.pochta.ru/tracking?barcode=${requestStatus?.MailId}`}>
                              {requestStatus?.MailId}
                            </a>
                          </label>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        )}
      </div>
      <div className={styles.acceptance}>
        {isLoading
          ?
          <div className={styles.title_wrapper}>
            <Skeleton variant='rounded' width='100%' height='40.5px'/>
          </div>
          :
          <p className={styles.text}>Запись на приемку</p>
        }
        {subjectType === 'fl'
          ?
          <Input
            disabled
            label="Номер телефона участника"
            onChange={(value) => setFlSubject([{
              ...flSubject[0],
              Phone: value
            }])}
            value={flSubject[0]?.Phone}
            placeholder="Введите номер телефона участника"
            mask='+7 (000) 000-00-00'
            skeleton={isLoading}
          />
          :
          <Input
            disabled
            label="Номер телефона участника"
            onChange={(value) => setUlSubject([{
              ...ulSubject[0],
              Phone: value
            }])}
            value={ulSubject[0]?.Phone}
            placeholder="Введите номер телефона участника"
            mask='+7 (000) 000-00-00'
            skeleton={isLoading}
          />
        }
        <Input
          label="Комментарий"
          isTextarea
          value={owner?.MakeAnAppointment?.Comment}
          onChange={(value) => setOwner({
            ...owner,
            MakeAnAppointment: {
              ...owner?.MakeAnAppointment,
              Comment: value
            }
          })}
          skeleton={isLoading}
        />
        <div>
          <Datepicker
            onChange={(value) => setOwner({
              ...owner,
              MakeAnAppointment: {
                ...owner?.MakeAnAppointment,
                DateTime: value
              }
            })}
            value={owner?.MakeAnAppointment?.DateTime}
            skeleton={isLoading}
          />
        </div>
        <div className={styles.manager}>
          <Input
            value={owner?.MakeAnAppointment?.ManagerName}
            onChange={handleInput}
            placeholder="Введите ФИО менеджера"
            label="ФИО менеджера"
            skeleton={isLoading}
          />
          <Input
            value={owner?.MakeAnAppointment?.ManagerPhone ? owner?.MakeAnAppointment?.ManagerPhone : null}
            onChange={(value) => setOwner({
              ...owner,
              MakeAnAppointment: {
                ...owner?.MakeAnAppointment,
                ManagerPhone: value
              }
            })}
            placeholder="Введите номер телефона менеджера"
            label="Номер телефона менеджера"
            mask='+7 (000) 000-00-00'
            skeleton={isLoading}
          />
        </div>
        <div>
          <div className={styles.check}>
            <Check
              label="Клиент принял объект"
              onClick={() => toggleStatus(0)}
              selected={owner?.MakeAnAppointment?.Status === 0}
              skeleton={isLoading}
            />
            {!isLoading && <TooltipIcon icon={info} tooltipText='Клиент подписал акт приема-передачи'/>}
          </div>
          {owner?.MakeAnAppointment?.Status === 0 &&
            <div>
              {isLoading
                ?
                <Skeleton variant='rounded' width='100%' height='60px'/>
                :
                <div className={cn(styles.signature, styles.documents)}>
                  <label>Дата подписания акта приема-передачи: {formatDate(owner?.MakeAnAppointment?.DateSigningAct)}</label>
                  <label>Крайний срок подачи на регистрацию: {addDays(owner?.MakeAnAppointment?.DateSigningAct, 25)}</label>
                  <div className={cn(color.error, styles.comments)}>
                    <label>Застройщик в течение 30 дней со дня подписания акта приема-передачи обязан зарегистрировать право собственности дольщика по заявлению застройщика и передать выписку из ЕГРН собственнику помещения</label>
                  </div>
                </div>
              }
            </div>
          }
          <div className={styles.check}>
            <Check
              label="Клиент принял объект с замечаниями"
              onClick={() => toggleStatus(1)}
              selected={owner?.MakeAnAppointment?.Status === 1}
              skeleton={isLoading}
            />
            {!isLoading && <TooltipIcon icon={info} tooltipText='Клиент подписал акт приема-передачи и протокол разногласий'/>}
          </div>
        </div>
        {owner?.MakeAnAppointment?.Status === 1 &&
          <div>
            {isLoading
              ?
              <Skeleton variant='rounded' width='100%' height='60px'/>
              :
              <div className={cn(color.error, styles.comments)}>
                <label>Дата окончания исправления замечания: 12.02.2024</label>
                <label>Осталось дней: 60 дней</label>
              </div>
            }
            <div className={styles.documents}>
              {owner?.MakeAnAppointment?.ProtocolRemarksName ? (
                <SmallCard
                  avatar={pdf}
                  title={owner?.MakeAnAppointment?.ProtocolRemarksName}
                  onRemove={() => setOwner({
                    ...owner,
                    MakeAnAppointment: {
                      ...owner?.MakeAnAppointment,
                      ProtocolRemarksName: null
                    }
                  })}
                  onClick={() => downloadStorageFile(apartment?.ProtocolRemarksId, owner?.MakeAnAppointment?.ProtocolRemarksName)}
                  skeleton={isLoading}
                />
              ) : (
                <FileInput
                  accept={[mimeTypes.Pdf]}
                  label='Загрузите файл протокола замечаний'
                  onChange={(file) => {
                    const formData = new FormData();
                    formData.append('File', file);

                    uploadFile(formData).then(id => {
                      setOwner({
                        ...owner,
                        MakeAnAppointment: {
                          ...owner?.MakeAnAppointment,
                          ProtocolRemarksId: id,
                          ProtocolRemarksName: file.name,
                        }
                      })
                    })
                  }}
                />
              )}
            </div>
          </div>
        }
        <ModalCenter isOpen={isOpenDeleteModal}>
          <div className={styles.delete}>
            <h4>Удалить участника?</h4>
            <Button
              type="error"
              onClick={() => deleteOwner(owner?.Id).then(() => {
                getResidentialApartment(apartment?.Id).then(selectedApartment => {
                  setApartment(selectedApartment);
                  if (selectedApartment.Owners.length === 0) {
                    setIsSharer(false)
                    setIsEditSubjectType(true)
                  }
                  if (setOpenParticipant) {
                    setOpenParticipant(false)
                  }
                  setIsOpenDeleteModal(false);
                  setOwner(null);
                  setOwnerId(null)
                })
              })}
              text="Удалить"
            />
            <Button
              type="gray"
              onClick={() => setIsOpenDeleteModal(false)}
              text="Отмена"
            />
          </div>
        </ModalCenter>
        <Signature
          isOpen={openSignatureModal}
          onClose={setOpenSignatureModal}
          fileName={owner?.NotificationFileName}
          onChange={changeSignature}
          fileId={owner?.NotificationFileId}
          participantId={owner?.FlOwnerId}
        />
      </div>
    </div>
  );
};

export default Participant;
