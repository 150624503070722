import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TableContainer from "../../../Components/Table/TableContainer";
import TableHeader from "../../../Components/Table/TableHeader";
import TableCell from "../../../Components/Table/TableCell";
import TableSkeleton from '../../../Components/Table/TableSkeleton';
import { useRequestService } from "../../../Services/Request/RequestService";
import Badge from "../../../Components/Badge/Badge";
import { Pagination } from '@mui/material';
import TableFooter from '../../../Components/Table/TableFooter';
import InnerCell from '../../../Components/Table/InnerCell';

import styles from "./request.list.module.css";

export default function RequestList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { getRequests, requestStatuses } = useRequestService();
  const [requests, setRequests] = useState([]);

  const handleChange = (e, number) => {
    setPage(number);
  };

  useEffect(() => {
    getRequests(page).then(result => setRequests(result))
  }, [page])

  return (
    <div className={styles.container}>
      <TableContainer>
        <TableHeader>
          <TableCell th="Тип заявления/ID/Участники" />
          <TableCell th="Дата создания" />
          <TableCell th="Номер КУВД/РР" />
          <TableCell th="Описание" />
          <TableCell th="Статус" />
          <TableCell th="Обновилась" />
        </TableHeader>

        <tbody className={styles.tbody}>
          {requests?.Items?.map((x) =>
            <tr
              onClick={() => {
                if (x?.StatusId === 2) {
                  navigate(`/request/edit/${x?.Id}/property-registration`);
                } else if (x?.StatusId === 5) {
                  navigate(`/request/rosreestr/${x?.Id}`);
                } else {
                  navigate(`/request/edit/${x?.Id}`);
                }
              }}
              className={styles.tr}
              key={x.Id}
            >
              <TableCell title={x.RegActionName} subtitle={`#${x.RequestNumber}`} />
              <TableCell title={x.DateCreate.split(" ")[0]} subtitle={x.DateCreate.split(" ")[1]}/>
              <InnerCell>
                <div className={styles.kuvd_wrapper}>
                  <span className={styles.kuvd}>{x.Kuvd}</span>
                  <span className={styles.kuvd_subtitle}>{x.RegistryNumber}</span>
                </div>
              </InnerCell>
              <TableCell subtitle={x?.Description} />
              <TableCell badge={<Badge type={requestStatuses.find(t => t.code == x.StatusId)?.style} text={requestStatuses.find(t => t.code == x.StatusId)?.name}/>} />
              <TableCell subtitle={x?.DateUpdate}/>
            </tr>
          )}
          {requests?.Items?.length === 0 &&
            <TableSkeleton col={10} row={5} />
          }
        </tbody>
        <tfoot className={styles.tfoot}>
        </tfoot>
      </TableContainer>
      <TableFooter>
        <Pagination
          count={Math.ceil(requests?.TotalPages)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          color="secondary"
        />
      </TableFooter>
    </div >
  )
}