import { useContext, useEffect, useState } from "react";
import requestStatuses from "./requestStatuses.json"
import documentTypes from "./documentTypes.json"
import objectTypes from "./objectTypes.json"
import regTypes from "./regTypes.json"
import rightTypes from "./rightTypes.json"
import { HttpContext } from "../../HttpContext";

export function useRequestService(requestId) {
    const { httpClient } = useContext(HttpContext)
    const [request, setRequest] = useState()
    const [status, setStatus] = useState(requestStatuses[0])

    useEffect(() => {
        if (!isNaN(parseInt(requestId))) {
            getRequest(requestId)
        }
        else {
            setRequest({ RegAction: { code: 5 } })
        }
    }, [requestId])


    const getRequest = async (requestId) => {
        return httpClient.get(`/request/${requestId}`).then(promise => {
            setRequest(promise.data);
            setStatus(requestStatuses.find(x => x.code == promise.data.Status.code))
            return promise.data;
        })
    }
    const getRequests = async (page) => {
        return httpClient.get(`/request/all?PageNumber=${page}&PageSize=15`).then(promise => promise.data)
    }

    const deleteDocument = async (id) => {
        return httpClient.delete(`/request/document/${id}`).then(promise => promise.data)
    }
    const deleteParticipant = async (id) => {
        return httpClient.delete(`/request/participant/${id}`).then(promise => promise.data)
    }
    const addFlSubject = async (flSubjectId, requestId) => {
        const data = {
            FlSubjectId: flSubjectId,
            RequestId: requestId,
        }
        return httpClient.post("/request/participant/flsubject", data).then(promise => promise)
    }
    const addFlSubjectWithAgent = async (flSubjectId, requestId, docTypeCode, issueDate, file, signature, flAgentId) => {
        const data = new FormData()
        data.append("FlSubjectId", flSubjectId)
        data.append("FlAgentId", flAgentId)
        data.append("DocTypeCode", docTypeCode)
        data.append("IssueDate", issueDate)
        data.append("File", file)
        data.append("Signature", signature)
        data.append("RequestId", requestId)
        data.append("TypeId", "0")

        return httpClient.post("/request/participant/flsubjectwithflagent", data).then(promise => promise)
    }
    const addUlAgent = async (data) => {
        return httpClient.post("/request/participant/ulagent", data).then(promise => promise)
    }
    const saveDocument = async (requestId, documentType, file, participantIds, sig) => {
        const data = new FormData()
        data.append("RequestId", requestId)
        data.append("DocTypeCode", documentType)
        data.append("File", file)
        data.append("Signature", sig)
        participantIds.map((id) =>
            data.append("ParticipantIds", id)
        );
        return httpClient.post('/request/document', data).then(promise => promise.data)
    }
    const generateStatement = async (requestId) => {
        return httpClient.post(`/request/statement/generate/${requestId}`).then(promise => promise.data)
    }
    const createRequest = async (regActionId, rightTypeId, obj) => {
        const data = {
            RegActionId: regActionId,
            RightTypeId: rightTypeId,
            Address: obj.Address,
            ObjectTypeId: parseInt(obj.Type.code),
            ObjectNote: request.Note,
            AreaContract: parseInt(obj.DduAreaContract),
            CadNumber: obj.CadNum,
            Address: obj.Address,
        }
        return httpClient.post(`/request`, data).then(promise => promise)
    }
    const updateRequest = async (request) => {
        return httpClient.put(`/request`, request).then(promise => promise.data)
    }
    const addSignature = async (documentId, participantId, file, base64, signType) => {
        const data = new FormData()
        data.append("DocumentId", documentId)
        data.append("ParticipantId", participantId)
        data.append("SignType", signType)
        if (file != null) {
            data.append("File", file)
        }
        if (base64 != null) {
            data.append("Base64", base64)
        }
        return httpClient.post("/request/document/signature", data).then()
    }
    const addUlRepresentingFl = async (body) => {
        return httpClient.post("/request/participant/ulAgentFlSubject", body).then(promise => promise.data)
    }
    const sendStatement = async (requestId) => {

        return httpClient.post(`/request/statement/rr/send/${requestId}`).then(promise => promise.data)
    }
    const addDocumentParticipant = async (documentId, participantId) => {
        const data = new FormData()
        data.append("DocumentId", documentId)
        data.append("ParticipantId", participantId)
        return httpClient.post("/request/document/participant", data).then(promise => promise.data)
    }
    const deleteDocumentParticipant = async (documentId, participantId) => {

        return httpClient.delete(`/request/document/${documentId}/participant/${participantId}`).then(promise => promise.data)
    }

    const updateFlSubject = async (body) => {
        return httpClient.put("/request/participant/flSubject", body).then(promise => promise.data)
    }

    const updateFlSubjectWithAgent = async (flSubjectId, requestId, docTypeCode, issueDate, file, signature, flAgentId) => {
        const data = new FormData()
        data.append("FlSubjectId", flSubjectId)
        data.append("FlAgentId", flAgentId)
        data.append("DocTypeCode", docTypeCode)
        data.append("IssueDate", issueDate)
        data.append("File", file)
        data.append("Signature", signature)
        data.append("RequestId", requestId)
        data.append("TypeId", "0")

        return httpClient.put("/request/participant/flsubjectwithflagent", data).then(promise => promise)
    }

    const updateUlAgent = async (body) => {
        return httpClient.put("/request/participant/ulAgent", body).then(promise => promise.data)
    }

    const updateUlRepresentingFl = async (body) => {
        return httpClient.put("/request/participant/ulAgentFlSubject", body).then(promise => promise.data)
    }

    return {
        request,
        regTypes,
        rightTypes,
        setRequest,
        getRequests,
        saveDocument,
        documentTypes,
        requestStatuses,
        deleteDocument,
        status,
        deleteParticipant,
        objectTypes,
        addFlSubject,
        addFlSubjectWithAgent,
        generateStatement,
        createRequest,
        addUlAgent,
        addSignature,
        addUlRepresentingFl,
        sendStatement,
        addDocumentParticipant,
        deleteDocumentParticipant,
        getRequest,
        updateRequest,
        updateFlSubject,
        updateFlSubjectWithAgent,
        updateUlAgent,
        updateUlRepresentingFl
    }
}