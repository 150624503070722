import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Skeleton } from '@mui/material';

import styles from "./check.module.css";
import empty from '../../images/Check_Empty.svg';
import checked from '../../images/Check.svg';

export default function Check({ label, onClick, disabled, selected, skeleton, bold }) {
  const [isChecked, setIsChecked] = useState(selected);

  const handleClick = () => {
    if (!disabled && onClick) {
      setIsChecked(!isChecked);
      onClick();
    }
  };

  useEffect(() => {
    setIsChecked(selected)
  }, [selected])

  return (
    <div className={styles.container}>
      {skeleton
        ?
        <label className={styles.skeleton}>
          <Skeleton variant='circular' width='18px' height='18px'/>
          <Skeleton width='170px' height='23px'/>
        </label>
        :
        <label onClick={handleClick} className={cn(styles.label, {[styles.disabled]: disabled, [styles.bold]: bold, [styles.selected]: selected && bold})}>
          <div className={styles.check}>
            <img src={isChecked ? checked : empty}/>
          </div>
          {label}
        </label>
      }
    </div>
  );
}