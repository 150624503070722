import React, { useEffect, useState } from 'react';

import CardHeader from '../../../Components/Card/CardHeader';
import CardContainer from '../../../Components/Card/CardContainer';
import CardBody from '../../../Components/Card/CardBody';
import { useEgrnService } from '../../../Services/Egrn/EgrnService';
import SmallCard from '../../../Components/SmallCard/SmallCard';
import { ArrowLeft } from '../../../Components/Icon/Icon';
import Button from '../../../Components/Button/Button';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';
import Cadastral from '../ObjectSearch/Cadastral/Cadastral';
import { CircularProgress } from '@mui/material';
import ModalCenterFull from '../../../Components/ModalCenter/ModalCenterFull';

import styles from './object.save.module.css'
import building from "../../../icons/building.svg"
import cn from 'classnames';

const ObjectSave = () => {
  const [objects, setObjects] = useState();
  const [saveData, setSaveData] = useState();
  const [isLoadingCadastral, setIsLoadingCadastral] = useState(false);
  const [cadastralData, setCadastralData] = useState(null);
  const [isOpenObjects, setIsOpenObjects] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {getObjectsGroup, getObject, getExcelFile, getObjectsInfo} = useEgrnService();

  function getObjectType(type) {
    switch (type) {
      case 'PARCEL':
        return {
          text: 'Земельный участок',
        };
      case 'FLAT':
        return {
          text: 'Помещение',
        };
      case 'OKS':
        return {
          text: 'Здание',
        };
      default:
        return {
          text: 'Неизвестно',
        };
    }
  }

  const getCadastralData = (cadastralNumber) => {
    setIsLoadingCadastral(true);
    getObjectsInfo(cadastralNumber)
      .then(response => {
        setCadastralData(response);
        setIsLoadingCadastral(false);
      })
      .catch(() => {
        setIsLoadingCadastral(false);
        setIsOpenModal(false)
      });
  }

  useEffect(() => {
    getObjectsGroup().then((data) => setObjects(data))
  }, [])

  return (
    <CardContainer>
      <CardHeader
        chevron={
          isOpenObjects &&
          <button
            className={styles.button}
            onClick={() => setIsOpenObjects(false)}
          >
            <ArrowLeft/>
          </button>
        }
        button={
          isOpenObjects &&
          <Button type='info' text='Скачать в Excel формате' onClick={() => {
            getExcelFile(saveData?.realObjects, saveData?.name).then((file) => console.log(file));
          }}/>
        }
        title={'Список сохраненных объектов'}
      />
      {isOpenObjects ?
        <div>
          {saveData?.realObjects?.length ?
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead className={styles.thead}>
                <tr>
                  <th className={styles.th}>Кадастровый номер</th>
                  <th className={styles.th}>Адрес</th>
                  <th className={styles.th}>Вид объекта недвижимости</th>
                </tr>
                </thead>
                <tbody className={styles.tbody}>
                {saveData?.realObjects?.map((item, index) => (
                  <tr key={index} className={styles.card_item} onClick={() => {
                    setIsOpenModal(true);
                    getCadastralData(item?.cadnum);
                  }}>
                    <td className={cn(styles.td, styles.bold)}>{item?.cadnum}</td>
                    <td className={styles.td}>{item?.full_name}</td>
                    <td className={styles.td}>{getObjectType(item?.type).text}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            :
            <div>
              <EmptyBlock title='Не найдено'/>
            </div>
          }
        </div>
        :
        <CardBody>
          {objects?.length ?
            <div className={styles.wrapper}>
              {objects?.map((object) =>
                <SmallCard
                  avatar={building}
                  onClick={() => {
                    getObject(object?.id).then((obj) => {
                      setIsOpenObjects(true);
                      setSaveData(obj);
                    });
                  }}
                  title={object.name}/>
              )}
            </div>
            :
            <div>
              <EmptyBlock title='Не найдено'/>
            </div>
          }
        </CardBody>
      }
      <ModalCenterFull
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        title={
          <div>
            <h2>Информация по объекту</h2>
          </div>
        }
      >
        {isLoadingCadastral ?
          <div className={styles.loader_wrapper}>
            <CircularProgress color="inherit"/>
            <p>Загружаем данные, пожалуйста, подождите...</p>
          </div>
          :
          <div className={styles.modal}>
            <Cadastral data={cadastralData}/>
          </div>
        }
      </ModalCenterFull>
    </CardContainer>
  );
};

export default ObjectSave;
