import Button from "../Button/Button";
import CardHeader from "../Card/CardHeader";

import styles from "./modal.center.module.css";

export default function ModalCenter({ isOpen, children }) {
    return (
      <div className={styles.modal_wrapper} style={{ display: isOpen ? "flex" : "none" }}>
        <div className={styles.modal}>
          {children}
        </div>
      </div>
    );
}