import React from 'react';
import style from './tooltipText.module.css';

const TooltipText = ({ text, maxLength }) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const middle = Math.floor(maxLength / 2);
    const start = text.slice(0, middle);
    const end = text.slice(text.length - middle);
    return `${start}...${end}`;
  };

  return (
    <div className={style.container}>
      <span className={style.text}>{truncateText(text, maxLength)}</span>
      {text.length > maxLength && (
        <div className={style.fullText}>{text}</div>
      )}
    </div>
  );
};

export default TooltipText;
