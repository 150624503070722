import axios from 'axios';

export function useEgrnService() {
  const apiKey = 'ad980932-ddff-4c45-af6b-46aa30471e6a';
  const apiLink = 'https://egrn.signself.ru'

  const getAllObjects = async (address) => {
    return axios.get(`${apiLink}/rulink/objects-query-forced`, {
      params: {
        filter: address
      },
      headers: {
        "X-Api-Key": apiKey
      }
    }).then(promise => promise.data)
  }

  const getObjects = async (address) => {
    return axios.get(`${apiLink}/rulink/objects-query`, {
      params: {
        filter: address
      },
      headers: {
        "X-Api-Key": apiKey
      }
    }).then(promise => promise.data)
  }

  const getObjectsInfo = async (cadastralNumber) => {
    return axios.get(`${apiLink}/rulink/object-info`, {
      params: {
        cadastralNumber: cadastralNumber
      },
      headers: {
        "X-Api-Key": apiKey
      }}).then(promise => promise.data)
  }

  const getObjectsGroup = async () => {
    return axios.get(`${apiLink}/api/saves/object-groups`, {
      headers: {
        "X-Api-Key": apiKey
      }}).then(promise => promise.data)
  }

  const getObject = async (id) => {
    return axios.get(`${apiLink}/api/saves/object-group/${id}`, {
      headers: {
        "X-Api-Key": apiKey
      }}).then(promise => promise.data)
  }

  const createObject = async (body) => {
    return axios.post(`${apiLink}/api/saves/objects`, body, {
      headers: {
        "X-Api-Key": apiKey
      }}).then(promise => promise.data)
  }

  const getExcelFile = async (body, name) => {
    return axios.post(`${apiLink}/file/get-excel-objects-query`, body, {
      headers: {
        "X-Api-Key": apiKey
      }}).then(promise => {
      try {
        const fileBlob = new Blob([promise.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileUrl = URL.createObjectURL(fileBlob);

        const downloadLink = document.createElement('a');
        downloadLink.href = fileUrl;
        downloadLink.download = `${name}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error('Download error:', error);
      }

    })
  }

  return {getObjectsInfo, getAllObjects, getObjects, getObjectsGroup, getObject, createObject, getExcelFile}
}
