import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CardHeader from "../../../Components/Card/CardHeader";
import Select from "../../../Components/Select/Select";
import Button from "../../../Components/Button/Button";
import CardContainer from "../../../Components/Card/CardContainer";
import CardBody from "../../../Components/Card/CardBody";
import FlSubjectEdit from "./FlSubjectEdit";
import UlSubjectEdit from "./UlSubjectEdit";
import AgentModal from "../Agent/AgentModal";
import useCustomerUpdate from './useCustomerUpdate';
import UlMembers from './UlMembers';
import { useUlService } from '../../../Services/UlSubjectService';
import { useFlService } from '../../../Services/FlSubjectService';
import SignMe from './SignMe/SignMe';
import Badge from '../../../Components/Badge/Badge';

import floppyIcon from "../../../icons/FloppyDisk.svg";
import styles from "./customer.edit.module.css";

export default function CustomerEdit() {
  const [agentModal, setAgentModal] = useState(false);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [customerType, setCustomerType] = useState(type === undefined ? "ul" : type);
  const [activeAgent, setActiveAgent] = useState(null);
  const [isSave, setIsSave] = useState(false);

  const { ulSubject, setUlSubject } = useUlService(id, customerType);
  const { flSubject, setFlSubject, deleteFlSubject } = useFlService(id, customerType);
  const { updateCustomer } = useCustomerUpdate();

  return (
    <div className={styles.container}>
      <CardContainer>
        <CardHeader title="Информация о клиенте"/>
        <CardBody>
          <div className={styles.separated}>
            <Select
              disabled={type !== undefined}
              value={customerType}
              onChange={(value) => setCustomerType(value)}
              label="Тип клиента"
            >
              <option value="ul">Юридическое лицо</option>
              <option value="fl">Физическое лицо</option>
            </Select>
          </div>
          {customerType === "ul" &&
            <UlSubjectEdit customer={ulSubject} setCustomer={(value) => setUlSubject(value)}/>
          }
          {customerType === "fl" &&
            <FlSubjectEdit customer={flSubject} setCustomer={(value) => setFlSubject(value)}/>
          }
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              isDisabled={isSave}
              icon={floppyIcon}
              text="Сохранить"
              onClick={() => {
                setIsSave(true)
                updateCustomer(customerType === 'fl' ? flSubject : ulSubject, customerType)
                  .then(() => setIsSave(false)).catch(() => setIsSave(false))
              }}
            />
            <Button onClick={() => deleteFlSubject(id).then(() => navigate(`/pages/customers`))} type='error' text="Удалить клиента"/>
          </div>
        </CardBody>
      </CardContainer>
      {customerType === "ul" &&
        <UlMembers
          ulSubject={ulSubject}
          setActiveAgent={setActiveAgent}
          setAgentModal={setAgentModal}
        />
      }
      {customerType === "fl" &&
        <CardContainer >
          <CardHeader title="Выпуск сертификата"/>
          <CardBody >
            <div className={styles.certificate_card}>
            <span className={styles.certificate_title}>
              Автовыпуск УКЭП в сервисе SignSelf
            </span>
              <Badge type="error" text="Запрос не отправлялся"/>
              <div className={styles.certificate_button} >
                <Button text="Отправить запрос на выпуск" type='primary'/>
              </div>
            </div>
            <div className={styles.certificate_card}>
            <span className={styles.certificate_title}>
              Госключ
            </span>
              <Badge type="error" text="Сертификат не выпускался"/>
              <div className={styles.certificate_button} >
                <Button text="Отправить запрос на выпуск" type='primary'/>
              </div>
            </div>
            <div className={styles.certificate_card}>
            <span className={styles.certificate_title}>
              УЦ «ИТ Мониторинг»
            </span>
              <Badge type="error" text="Сертификат не выпускался"/>
              <div className={styles.certificate_button} >
                <Button text="Отправить запрос на выпуск" type='primary'/>
              </div>
            </div>
            <SignMe />
          </CardBody>
        </CardContainer>
      }
      <CardContainer>
        <CardHeader title="Список заявок для данного клиента"/>
      </CardContainer>
      <AgentModal
        customer={customerType === "fl" ? flSubject : ulSubject}
        agent={activeAgent}
        setAgent={(x) => setActiveAgent(x)}
        isOpen={agentModal}
        setOpen={setAgentModal}
      />
    </div>
  )
}