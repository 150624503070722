import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Navlink from '../../Components/Navlink/Navlink';
import links from "./links.js";
import { jwtDecode } from "jwt-decode";
import Badge from '../../Components/Badge/Badge.jsx';
import { HttpContext } from '../../HttpContext.jsx';

import logo from "../../images/logo.svg";
import styles from './navbar.module.css';
import exit from "../../icons/Exit.svg"
import wallet from "../../icons/Wallet.svg"
import cornerDownRight from "../../icons/cornerDownRight.svg"

export default function NavBar() {
  const decoded = jwtDecode(localStorage.getItem("token"));

  const location = useLocation();
  const [url, setUrl] = useState(location.pathname)
  const { httpClient } = useContext(HttpContext)
  const [balance, setBalance] = useState(0)
  const getBalance = async () => {
    return httpClient.get("/balance").then(promise => promise.data);
  }
  useEffect(() => {
    getBalance().then(x => setBalance(x))
    setUrl(location.pathname)
  }, [location]);

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img src={logo} width="230px" height="32px" style={{ marginBottom: 24 }} />

        {links.map((x, index) =>
          <Navlink key={index} href={x.path} active={url.includes(x.page)} text={x.title} icon={x.iconUrl} />
        )}
        {url.includes('object') &&
          <div>
            <Navlink activeSave={url.includes('save')} icon={cornerDownRight} href="/object/save" text='Сохраненные' />
          </div>
        }
        <div className={styles.customer}>
          <span>{decoded.customer_name}</span>
        </div>

        <Navlink text="Баланс" icon={wallet} badge={<Badge type='success' text={`${balance} пакетов`}/>}/>
        <Navlink text="Выход" href="/login" icon={exit}/>
      </div>

      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
}
